import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 4-4-4-4\\@85% 1RM`}</p>
    <p>{`Single Leg Hamstring Curls (ball or foam roller) 4-4-4-4/leg`}</p>
    <p>{`then,`}</p>
    <p>{`30-20-10 each of:`}</p>
    <p>{`Pistols (total, alternating legs)`}</p>
    <p>{`Calorie Row`}</p>
    <p>{`T2B`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p">{`*`}{`The CrossFit Games are live starting Thursday! We’ll be having a
members get together at the Barnett’s house Sunday, August 6th, from
12-6. We’ll eat, watch the Games, and swim if you’d like. This is a
family friendly event so bring the kids! Please bring a side dish (if
your last name starts with A-M) or dessert (if your last name starts
with N-Z). We’ll supply the meat and drinks. Email, call or text Daniel
to RSVP and for directions.  Sunday’s 12:30 class will be cancelled for
the party.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      